.navigation {
  background: $white-color;
  padding: 20px 0;
}

.navigation .navbar {
  margin-bottom: 0px;
  border: 0px;
}

.navigation .navbar-brand {
  padding-top: 5px;
}

.navigation .navbar {
  background: none;
  border: 0px;
}

.navigation .navbar .navbar-nav {
  padding-top: 10px;
}

.navigation .navbar .navbar-nav > li > a {
  color: #5C5C5C;
  padding: 10px 15px;
  font-weight: 900;
  font-size: 14px;
  text-transform: uppercase;
}

.navigation .navbar .navbar-nav > li > a:hover, .navigation .navbar .navbar-nav > li > a:focus {
  color: #000;
  background: transparent;
}

.navigation .navbar .dropdown-menu {
  border-radius: 0;
  border: none;
  drop-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08);
}

.navigation .navbar .dropdown-menu a {
  font-weight: normal;
  color: #808080;
  padding: 10px 20px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.navigation .navbar .dropdown-menu a:hover {
  background: $brand-color;
  color: $white-color;
  padding-left: 25px;
}

.nav .open > a {
  background: transparent;
}

.footer-navigate {
  text-align: center;
  padding: 10px 0 35px;
  margin: 50px 0;
  background: darken($background-color, 2%);
  @include relative-line-height(1);

  a {
    color: #595959;
    @include relative-font-size(1);
  }

  a:hover {
    color: $brand-color;
    text-decoration: none;
    @include relative-font-weight(1.5);
  }
  
  p {
    color: #909090;
  }
}


// @include media-query($on-laptop) {
//   .footer-col-1,
//   .footer-col-2 {
//     width: -webkit-calc(50% - (#{$spacing-unit} / 2));
//     width:         calc(50% - (#{$spacing-unit} / 2));
//   }
// 
//   .footer-col-3 {
//     width: -webkit-calc(100% - (#{$spacing-unit} / 2));
//     width:         calc(100% - (#{$spacing-unit} / 2));
//   }
// }
// 
// @include media-query($on-palm) {
//   .footer-col {
//     float: none;
//     width: -webkit-calc(100% - (#{$spacing-unit} / 2));
//     width:         calc(100% - (#{$spacing-unit} / 2));
//   }
// }


/*=================================================================
 Page
==================================================================*/
.page {
  padding: $spacing-unit 0;

  h1 {
    @include relative-font-size(2.25);
    padding: 0 0 $spacing-unit;
  }

  h3, h4 {
    padding: $spacing-unit/2 0;
    @include relative-line-height(1.1);
  }

  h4 {
    border-top: solid #e2e8e1 1px;
  }

  a, a:active, a:visited, a:focus, a:hover {
    text-decoration: none;
    color: $brand-color;
    @include relative-font-weight(1.5);
  }

  a:hover {
    color: $brand-color-light;
  }

  .btn-default, .btn-default:hover, .btn-default:visited {
    display: inline-block;
    background: $grey-color-light;
    color: $text-color;
    @include relative-font-size(1);
    padding: 10px 20px;
    margin: 5px 10px;
  }

  p {
    @include relative-line-height(1);
  }

  th, td {
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(odd) {background-color: darken($background-color, 4%);}
}

/*=================================================================
 Page
==================================================================*/
.page-404 {
  text-align: center;
}

.page-404 h1 {
  font-size: 300px;
  font-weight: bold;
  line-height: 300px;
  margin-top: 30px;
}

@media (max-width: 400px) {
  .page-404 h1 {
    font-size: 100px;
    line-height: 100px;
  }
}

@media (max-width: 480px) {
  .page-404 h1 {
    font-size: 130px;
    line-height: 150px;
  }
}

@media (max-width: 768px) {
  .page-404 h1 {
    font-size: 150px;
    line-height: 200px;
  }
}

.page-404 h2 {
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 4px;
  font-weight: bold;
  margin-top: 30px;
}

/*=================================================================
  Blog Page
==================================================================*/

.post-sub-heading {
  border-bottom: 1px solid #dedede;
  padding-bottom: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 20px;
}

.post-social-share {
  margin-bottom: 50px;
}

.post-comments {
  margin: 30px 0;
}

.post-comments .media {
  margin-top: 20px;
}

.post-comments .media > .pull-left {
  padding-right: 20px;
}

.post-comments .comment-author {
  margin-top: 0;
  margin-bottom: 0px;
  font-weight: 500;
}

.post-comments .comment-author a {
  color: $brand-color;
  font-size: 14px;
  text-transform: uppercase;
}

.post-comments time {
  margin: 0 0 5px;
  display: inline-block;
  color: #808080;
  font-size: 12px;
}

.post-comments .comment-button {
  color: $brand-color;
  display: inline-block;
  margin-left: 5px;
  font-size: 12px;
}

.post-comments .comment-button i {
  margin-right: 5px;
  display: inline-block;
}

.post-comments .comment-button:hover {
  color: $brand-color;
}

.post-excerpt {
  margin-bottom: 60px;
}

.post-excerpt h3 a {
  color: #000;
}

.post-excerpt p {
  margin: 0 0 30px;
}

.post-excerpt blockquote.quote-post {
  margin: 20px 0;
}

.post-excerpt blockquote.quote-post p {
  line-height: 30px;
  font-size: 20px;
  color: $brand-color;
}

.single-blog {
  background-color: $white-color;
  margin-bottom: 50px;
  padding: 20px;
}

.blog-subtitle {
  font-size: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.next-prev {
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  margin: 20px 0;
  padding: 25px 0;
}

.next-prev a {
  color: #000;
}

.next-prev a:hover {
  color: $brand-color;
}

.next-prev .prev-post i {
  margin-right: 10px;
}

.next-prev .next-post i {
  margin-left: 10px;
}

.social-profile ul li {
  margin: 0 10px 0 0;
  display: inline-block;
}

.social-profile ul li a {
  color: #4e595f;
  display: block;
  font-size: 16px;
}

.social-profile ul li a i:hover {
  color: $brand-color;
}

.comments-section {
  margin-top: 35px;
}

.author-about {
  margin-top: 40px;
}

.post-author {
  margin-right: 20px;
}

.post-author > img {
  border: 1px solid #dedede;
  max-width: 120px;
  padding: 5px;
  width: 100%;
}

.comment-list ul {
  margin-top: 20px;
}

.comment-list ul li {
  margin-bottom: 20px;
}

.comment-wrap {
  border: 1px solid #dedede;
  border-radius: 1px;
  margin-left: 20px;
  padding: 10px;
  position: relative;
}

.comment-wrap .author-avatar {
  margin-right: 10px;
}

.comment-wrap .media .media-heading {
  font-size: 14px;
  margin-bottom: 8px;
}

.comment-wrap .media .media-heading a {
  color: $brand-color;
  font-size: 13px;
}

.comment-wrap .media .comment-meta {
  font-size: 12px;
  color: #888;
}

.comment-wrap .media p {
  margin-top: 15px;
}

.comment-reply-form {
  margin-top: 80px;
}

.comment-reply-form input, .comment-reply-form textarea {
  height: 35px;
  border-radius: 0;
  box-shadow: none;
}

.comment-reply-form input:focus, .comment-reply-form textarea:focus {
  box-shadow: none;
  border: 1px solid $brand-color;
}

.comment-reply-form textarea, .comment-reply-form .btn-main, .comment-reply-form .btn-transparent, .comment-reply-form .btn-small {
  height: auto;
}

.widget {
  margin-bottom: 30px;
  padding-bottom: 35px;
}

.widget .widget-title {
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
  border-bottom: 1px solid #dedede;
}

.widget.widget-latest-post .media .media-object {
  width: 100px;
  height: auto;
}

.widget.widget-latest-post .media .media-heading a {
  color: #000;
  font-size: 16px;
}

.widget.widget-latest-post .media p {
  font-size: 12px;
  color: #808080;
}

.widget.widget-category ul li {
  margin-bottom: 10px;
}

.widget.widget-category ul li a {
  color: #837f7e;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.widget.widget-category ul li a:before {
  padding-right: 10px;
}

.widget.widget-category ul li a:hover {
  color: $brand-color;
  padding-left: 5px;
}

.widget.widget-tag ul li {
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 5px;
}

.widget.widget-tag ul li a {
  color: #837f7e;
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid #dedede;
  border-radius: 30px;
  font-size: 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.widget.widget-tag ul li a:hover {
  color: $white-color;
  background: $brand-color;
  border: 1px solid $brand-color;
}

/* Algolia Search */
.ais-search-box {
  max-width: 100%;
  margin-bottom: 15px;
}

/**
 * Blog Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}

.post {
  background: $white-color;
  margin-bottom: 40px;
  padding-bottom: 20px;
}

.post .post-media.post-thumb img {
  width: 100%;
  height: auto;
}

.post .post-media.post-media-audio iframe {
  width: 100%;
}

.post h1 {
  margin: 25px 0 0;
//   text-transform: uppercase;
  color: $text-color;
  @include relative-font-weight(1.5);

}

.post h1 .ais-Highlight {
  color: #111;
  font-style: normal;
  text-decoration: underline;
}

.post h2 .ais-Highlight {
  color: #111;
  font-style: normal;
  text-decoration: underline;
}

.post h1 a {
  color: $text-color;
}

.post h1 a:hover {
  color: $brand-color;
}

.post .post-meta {
  @include relative-font-size(0.8);
  margin: 10px 0;
}

.post .post-meta ul {
  padding: 0px;
}

.post .post-meta ul li {
  display: inline-block;
  color: #909090;
  margin-right: 20px;
  font-size: 12px;
  letter-spacing: .5px;
}

.post .post-meta ul li a {
  color: #909090;
}

.post .post-meta ul li a:hover {
  color: $brand-color;
}

.post .post-meta .post-author {
  color: #000;
}

.post .post-content {
  margin-top: 28px;
}

.post .post-content .ais-Highlight {
  color: $brand-color;
  font-style: normal;
  font-weight: bold;
}

.post .post-content p {
  line-height: 26px;
//  @include relative-line-height(1);
}

.post .post-content ul li {
  line-height: 26px;
  //  @include relative-line-height(1);
}

.post .post-content > p:first-child:first-letter {
  color: $text-color;
  float: left;
  font-family: $base-font-family;
  @include relative-font-size(3.5);
  @include relative-font-weight(1.1);
   padding: 14px 8px 0 0;
}

.post .post-content img {
  max-width: 100%;
  height: auto;
  margin: 20px;
  float: right;
}

.post .post-content .btn-main, .post .post-content .btn-transparent, .post .post-content .btn-small {
  padding: 10px 20px;
  margin: 15px 0;
  font-size: 12px;
}

.post .post-excerpt {
  margin-top: 50px;
}

.post .post-excerpt .ais-Highlight {
  color: #2a7ae2;
  font-style: normal;
  font-weight: bold;
}

.post .post-excerpt p {
  line-height: 26px;
}

.post-pagination {
  margin-top: 70px;
}

.post-pagination > li {
  margin: 0 2px;
  display: inline-block;
  font-size: 14px;
}

.post-pagination > li > a {
  color: #000;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.post-pagination > li > a:hover {
  color: $white-color;
  background: $brand-color;
  border: 1px solid $brand-color;
}

.post-pagination > li.active > a {
  background: $brand-color !important;
  border: 1px solid $brand-color !important;
}

.post-pagination > li:first-child > a, .post-pagination > li:last-child > a {
  border-radius: 0;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $brand-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Contact
 */
input[type="email"], input[type="password"], input[type="text"], input[type="tel"] {
  box-shadow: none;
  height: 45px;
  outline: none;
  font-size: 14px;
}

input[type="email"]:focus, input[type="password"]:focus, input[type="text"]:focus, input[type="tel"]:focus {
  box-shadow: none;
  border: 1px solid $brand-color;
}

.form-control {
  box-shadow: none;
  border-radius: 0;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid $brand-color;
}

// .btn-main, .btn-transparent, .btn-small {
//   background: $brand-color;
//   color: $white-color;
//   display: inline-block;
//   font-size: 14px;
//   letter-spacing: 1px;
//   padding: 14px 35px;
//   text-transform: uppercase;
//   border-radius: 0;
//   -webkit-transition: all 0.2s ease;
//   transition: all 0.2s ease;
// }
// 
// .btn-main.btn-icon i, .btn-icon.btn-transparent i, .btn-icon.btn-small i {
//   font-size: 16px;
//   vertical-align: middle;
//   margin-right: 5px;
// }
// 
// .btn-main:hover, .btn-transparent:hover, .btn-small:hover {
//   background: #353240;
//   color: $white-color;
// }
// 
// .btn-solid-border {
//   border: 1px solid $white-color;
//   background: transparent;
//   color: $white-color;
// }
// 
// .btn-solid-border:hover {
//   border: 1px solid $brand-color;
//   background: $brand-color;
// }
// 
// .btn-transparent {
//   background: transparent;
//   padding: 0;
//   color: $brand-color;
// }
// 
// .btn-transparent:hover {
//   background: transparent;
//   color: $brand-color;
// }
// 
// .btn-large {
//   padding: 20px 45px;
// }
// 
// .btn-large.btn-icon i {
//   font-size: 16px;
//   vertical-align: middle;
//   margin-right: 5px;
// }
// 
// .btn-small {
//   padding: 10px 25px;
//   font-size: 12px;
// }
// 
// .btn-round {
//   border-radius: 4px;
// }
// 
// .btn-round-full {
//   border-radius: 50px;
// }
// 
// .btn.active:focus, .btn:active:focus, .btn:focus {
//   outline: 0;
// }
// 
// .btn:focus {
//   color: #ddd;
// }

// #preloader {
//   background: $white-color;
//   height: 100%;
//   left: 0;
//   opacity: 1;
//   filter: alpha(opacity=100);
//   position: fixed;
//   text-align: center;
//   top: 0;
//   width: 100%;
//   z-index: 999999999;
// }

.bg-shadow {
  background-color: $white-color;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.08);
  padding: 20px;
}

.bg-gray {
  background: #f9f9f9;
}

.bg-primary {
  background: $brand-color;
}

.bg-primary-dark {
  background: #4d485d;
}

.bg-primary-darker {
  background: #353240;
}

.bg-dark {
  background: #202122;
}

.section {
  padding: 100px 0;
}

.section-sm {
  padding: 70px 0;
}

.title {
  padding: 20px 0 30px;
}

.title h2 {
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.section {
  padding: 30px 0;

  h2 {
    padding-top: 35px;
    line-height: 27px;
    margin: 0;
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 600; 
  }

  h4 {
    padding-top: 35px;
    line-height: 27px;
    margin: 0;
    font-family: 'Volkhov', serif;
    font-style: italic;
  }

  p {
    padding-top: 20px;
    font-size: 18px;
  }
}

.section-bg-image {
  padding: 0 0 1%;
  position: relative;
  overflow: visible;
  width: 100%;
  height: auto;
  background-position: center center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;

  @include media-query-foundation($small-up) {
    background-attachment: scroll;
  }
  @include media-query-foundation($large-up) {
    background-attachment: fixed;
  }
}

.section-bg-image-none {
  background-color: #fff;
}

.section-default-dark {
  @extend .section;
  h2, h4, p {
//     color: $text-color-gray;
  }

  a, a:active, a:visited, a:focus {
    text-decoration: none;
    color: $text-color;
  }
  
  a:hover {
    text-decoration: none;
//     @include relative-font-size(1.25);
    color: $text-color;
  }

  .btn-default, .btn-default:hover {
    display: inline-block;
    background: $grey-color-light;
    color: $text-color;
    @include relative-font-size(1.25);
    padding: 10px 20px;
    margin: 5px 0;
  }

}

.section-default-light {
  @extend .section;
  @include relative-font-weight(2);
  h2, h4, p {
    color: $white-color;
  }
  
  a, a:active, a:visited, a:focus {
    text-decoration: none;
    color: $white-color;
  }
  
  a:hover {
    text-decoration: none;
//     @include relative-font-size(1.25);
    color: $white-color;
  }
 
  .btn-default, .btn-default:hover, .btn-default:visited {
    display: inline-block;
    background: $brand-color;
    color: $white-color;
    border: none;
    @include relative-font-size(1.25);
    padding: 10px 20px;
    margin: 5px 0;
  }
}

.section-contact {
  @extend .section;
  // padding-top: 70px;
//   padding-bottom: 35px;
  h2, h4, p {
//     color: $text-color-gray;
  }
  
  .block {
    form .form-group {
      padding-bottom: 15px;
      margin: 0px;
    }

    form .form-group .form-control {
      background: #F6F8FA;
      height: 60px;
      border: 1px solid #EEF2F6;
      box-shadow: none;
      width: 100%;
    }

    form .form-group .form-control textarea {
      background: #F6F8FA;
      height: 135px;
      border: 1px solid #EEF2F6;
      box-shadow: none;
      width: 100%;
    }

    button {
      width: 100%;
      height: 50px;
      background: $brand-color;
      border: none;
      color: $white-color;
      font-size: 18px;
    }
  }
}



.heading {
  padding-bottom: 60px;
  text-align: center;
}

.heading h2 {
  color: #000;
  font-size: 30px;
  line-height: 40px;
  font-weight: 400;
}

.heading p {
  font-size: 18px;
  line-height: 40px;
  color: #292929;
  font-weight: 300;
}


/*=================================================================
  Pricing section
==================================================================*/
.pricing-table .pricing-item {
  padding: 40px 20px;
  background: #fff;
  box-shadow: 0 8px 15px 0 rgba(5, 57, 106, 0.06);
}

.pricing-table .pricing-item a.btn-main, .pricing-table .pricing-item a.btn-transparent, .pricing-table .pricing-item a.btn-small {
  text-transform: uppercase;
  margin-top: 20px;
}

.pricing-table .pricing-item li {
  font-weight: 400;
  padding: 6px 0;
  color: #666;
}

.pricing-table .pricing-item li i {
  margin-right: 6px;
  color: #655E7A;
}

.pricing-table .price-title {
  padding: 30px 0 20px;
}

.pricing-table .price-title > h3 {
  font-weight: 700;
  margin: 0 0 5px;
  font-size: 15px;
  text-transform: uppercase;
}

.pricing-table .price-title > p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 5px;
}

.pricing-table .price-title .value {
  color: #655E7A;
  font-size: 50px;
  padding: 10px 0;
}

/*=================================================================
  Responsive video
==================================================================*/

//.video-container {
//  position: relative;
//  padding-bottom: 56.25%;
//  padding-top: 30px; height: 0; overflow: hidden;
//}
//
//.video-container iframe,
//.video-container object,
//.video-container embed {
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//}

.video-holder {
   position: relative;
   width: 100%;
   height: 0;
   padding-bottom: 56.25%;
   overflow: hidden;
 }

.video-holder iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
