body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

body {
//   font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  line-height: $base-line-height;
  font-family: $base-font-family;
//   -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
//   -webkit-font-feature-settings: "kern" 1;
//      -moz-font-feature-settings: "kern" 1;
//        -o-font-feature-settings: "kern" 1;
//           font-feature-settings: "kern" 1;
//   font-kerning: normal;
}

// h1, h2, h3, h4, h5, h6,
// p, blockquote, pre,
// ul, ol, dl, figure,
// %vertical-rhythm {
//   margin-bottom: $spacing-unit / 2;
// }

img {
  max-width: 100%;
  vertical-align: middle;
}

figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $base-font-family;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: $base-font-weight;
}

h1 {
  @include relative-font-size(2.5);
}

h2 {
  @include relative-font-size(1.75);
}

h3 {
  @include relative-font-size(1.25);
}

h4 {
  @include relative-font-size(1.25);
}

p {
  color: $text-color-gray;
  font-size: $base-font-size;
}

ol {
  padding: 0 0 0 20px;
  list-style: decimal;
}

ul {
  padding: 0 0 0 20px;
  list-style-type: square;
}

li {
  list-style-position: outside;
  color: $text-color-gray;
  @include relative-font-size(1);
  @include relative-line-height(1);
}

iframe {
  border: 0;
}

a, a:focus, a:hover {
  text-decoration: none;
  outline: 0;
  color: $brand-color;
}

/**
 * Blockquotes
 */
 blockquote {
  @include relative-font-size(1.125);
  border-color: #655E7A;
  padding: 20px 40px;
  text-align: left;
  color: $text-color-gray;
}

/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;
  @extend %clearfix !optional;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width:         calc(#{$content-width} - (#{$spacing-unit}));
    padding-right: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
  }
}
