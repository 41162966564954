@charset "utf-8";

// Define defaults for each variable
$base-font-family: "Roboto", sans-serif !default;
$other-font-family: "Volkhov", serif !default;
$body-font-family: "Open Sans", sans-serif !default;

$base-font-size:   16px !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-font-weight: 400 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$black-color:       #111 !default;
$grey-color:        #7b7b7b !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$white-color:      #ffffff !default;

$text-color:       $black-color !default;
$text-color-gray:  $grey-color !default;
$background-color: #fdfdfd !default;
$footer-background-color: #F5F5F5 !default;
$brand-color:      #5f7a5e !default;
$brand-color-dark: darken($brand-color, 30%) !default;
$brand-color-light: lighten($brand-color, 10%) !default;


@function lower-bound($range){
  @if length($range) <= 0 {
    @return 0;
  }
  @return nth($range,1);
}

@function upper-bound($range) {
  @if length($range) < 2 {
    @return 999999999999;
  }
  @return nth($range, 2);
}

// Width of the content area
$content-width:    800px !default;
$on-palm:          600px !default;
$on-laptop:        800px !default;
$on-small-screen:  640px  !default;
$on-medium-screen: 1024px !default;
$on-large-screen:  1440px !default;
$on-xlarge-screen: 1920px !default;

$small-range:   (0, $on-small-screen) !default; /* 0, 640px */
$medium-range:  ($on-small-screen  + 1, $on-medium-screen) !default; /* 641px, 1024px */
$large-range:   ($on-medium-screen + 1, $on-large-screen)  !default; /* 1025px, 1440px */
$xlarge-range:  ($on-large-screen  + 1, $on-xlarge-screen) !default; /* 1441px, 1920px */
$xxlarge-range: ($on-xlarge-screen+ 1, 6250000) !default; /* 1921px, ... */

// Media Queries
$screen: "only screen" !default;

$landscape: "#{$screen} and (orientation: landscape)" !default;
$portrait: "#{$screen} and (orientation: portrait)" !default;

$small-up: $screen !default;
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})" !default;

$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})" !default;

$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})" !default;

$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})" !default;
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})" !default;

$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;

$retina: (
  "#{$screen} and (-webkit-min-device-pixel-ratio: 2)",
  "#{$screen} and (min--moz-device-pixel-ratio: 2)",
  "#{$screen} and (-o-min-device-pixel-ratio: 2/1)",
  "#{$screen} and (min-device-pixel-ratio: 2)",
  "#{$screen} and (min-resolution: 192dpi)",
  "#{$screen} and (min-resolution: 2dppx)"
);

@media #{$small-up} { 
}
@media #{$small-only} { }

@media #{$medium-up} {
}
@media #{$medium-only} { }

@media #{$large-up} { }
@media #{$large-only} { }

@media #{$xlarge-up} { }
@media #{$xlarge-only} { }

@media #{$xxlarge-up} { }
@media #{$xxlarge-only} { }

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin media-query-foundation($query) {
  @media #{$query} {
    @content
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

@mixin relative-font-weight($ratio) {
  font-weight: $base-font-weight * $ratio;
}

@mixin relative-line-height($ratio) {
  line-height: $base-line-height * $ratio;
}

// Import partials
@import
  "airspace/base",
  "airspace/layout",
  "airspace/syntax-highlighting",
  url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700),
  url(https://fonts.googleapis.com/css?family=Oswald:300,400,500,600),
  url(https://fonts.googleapis.com/css?family=Volkhov:400italic)
;
